import { graphql } from "gatsby"
import { compose } from "recompose"

import BicycleProductPage from "../views/BicycleProductPage"
import withTransformData from "../utils/withTransformData"

export const query = graphql`
  query BicycleProduct($bikeSlug: String!, $images: [String!]) {
    bike: contentfulBicycleModel(internalTitle: { eq: $bikeSlug }) {
      title
      style
      type
      gender
      internalTitle
      formattedTitle
      banner {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileBanner {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bannerSlug
      speeds {
        internalTitle
        contentful_id
        speed
        seoTitle
        seoDescription {
          seoDescription
        }
        showContactNumber
        alternatePhoneNumber
        variants {
          contentful_id
          internalTitle
          shopifyId
          sku
          imageLayout {
            internal {
              content
            }
          }
          productListingImage {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          color {
            hexCode
            name
          }
          hideCustomizerButton
          hideNotifyMeButton
          accessoryUpsell {
            contentful_id
            title
            accessory_model {
              title
              internalTitle
            }
            shopifyId
            internalTitle
            imageLayout {
              internal {
                content
              }
            }
            productListingImage {
              fluid(maxWidth: 1000, quality: 85) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            color {
              hexCode
              name
            }
          }
          cartAddons {
            contentful_id
            title
            accessory_model {
              title
            }
            shopifyId
            internalTitle
            imageLayout {
              internal {
                content
              }
            }
            productListingImage {
              fluid(maxWidth: 1000, quality: 85) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            color {
              hexCode
              name
            }
          }
        }
      }
      bodyFitMaxHeight
      bodyFitMaxWeight
      bodyFitMinHeight
      enableFreeShippingMessage
      enable365DayTestRideMessage
      enable90DayTestRideMessage
      enableNationwideServiceMessage
      enableWarrantyMessage
      disableWarranty
      seoTitle
      seoDescription {
        seoDescription
      }
      seoBackgroundImage {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      seoContentBlock {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            fluid(maxWidth: 1440, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      seoContentBlockVideo {
        seoContentBlockVideo
      }
    }
    images: allContentfulAsset(filter: { contentful_id: { in: $images } }) {
      edges {
        node {
          contentful_id
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          raw: fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    messages: contentfulGlobalConfiguration {
      freeShippingModalTitle
      freeShippingMessage {
        raw
      }
      testRideModalTitle
      dayTestRideDescription {
        raw
      }
      ninetyDayTestRideModalTitle
      ninetyDayTestRideModalDescription {
        raw
      }
    }
  }
`

export default compose(withTransformData)(BicycleProductPage)
