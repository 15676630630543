import React from "react"
import {
  Flex,
  Input,
  InputProps,
  FlexProps,
  InputGroup,
  InputRightElement,
  Icon,
  InputGroupProps,
} from "@chakra-ui/core"

import { Button } from "../Buttons"
import Body, { bodyProps } from "../typography/Body"
import SmallCaps from "../typography/SmallCaps"
import { LaptopDownOnly, LaptopUpOnly } from "../../utils/MediaQueries"
import { BodyFitType } from "../../redux/types/UserTypes"

export type FitType = "fit" | "no-fit" | null

const CustomInput: React.FC<
  Omit<InputGroupProps, "children"> & {
    units: React.ReactNode
    inputProps: InputProps
  }
> = ({ units, inputProps, ...props }) => {
  const hasValue = inputProps.value || inputProps.value === 0
  const valueLength = inputProps.value?.toString().length || 0
  const placeholderLength = inputProps.placeholder?.length || 0
  return (
    <InputGroup {...props}>
      <Input
        type="number"
        h="3rem"
        textAlign="center"
        borderColor="dividerLine"
        backgroundColor="transparent"
        pl="0.5rem"
        pr={
          hasValue ? `calc(${0.667 * placeholderLength}ch + 0.25rem)` : "0.5rem"
        }
        {...bodyProps("md")}
        fontWeight={hasValue ? "bold" : "normal"}
        boxSizing="content-box"
        {...inputProps}
      />
      {hasValue && (
        <InputRightElement
          h="3rem"
          w="auto"
          fontSize="0.75rem"
          color="dawn"
          pointerEvents="none"
          right={`0.375rem`}
          transform="translateY(0.125rem)"
        >
          {units}
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export interface QuickFitProps {
  onFit: (values: BodyFitType | null) => void
  fitStatus?: "fit" | "no-fit" | null
  bodyFit?: BodyFitType | null
}

const QuickFit: React.FC<
  QuickFitProps & Omit<FlexProps, "minHeight" | "maxHeight">
> = ({ onFit, fitStatus, bodyFit, ...props }) => {
  const [values, setValues] = React.useState<{
    weight: string
    heightFt: string
    heightIn: string
  }>({
    weight: bodyFit?.weight.toString() || "",
    heightFt: bodyFit?.heightFt.toString() || "",
    heightIn: bodyFit?.heightIn.toString() || "",
  })

  const getFit = (): BodyFitType | null => {
    if (!values.heightFt || !values.heightIn || !values.weight) {
      return null
    }

    return {
      heightFt: Number(values.heightFt),
      heightIn: Number(values.heightIn),
      weight: Number(values.weight),
    }
  }
  const onChange = (value: string, key: string, maxLength: number) => {
    const numValue =
      value === ""
        ? ""
        : value.length <= maxLength
        ? Math.max(0, Number(value))
        : values[key]
    setValues({
      ...values,
      [key]: numValue.toString(),
    })
  }

  const resetFit = () => {
    setValues({ weight: "", heightFt: "", heightIn: "" })
    onFit(null)
  }

  return (
    <Flex
      w="100%"
      direction="column"
      justify="center"
      borderRadius="0.5rem"
      border="1px solid"
      borderColor="dividerLine"
      px="1.875rem"
      py="1.5625rem"
      {...props}
    >
      <Flex align="center">
        <Icon name="info" color="primary" mr="0.5rem" size="1.25rem" />
        <LaptopDownOnly>
          <Body color="primary" size="sm" lineHeight="1.125rem">
            Make sure it fits
          </Body>
        </LaptopDownOnly>
        <LaptopUpOnly>
          <Body color="primary" lineHeight="1.125rem">
            Make sure it fits
          </Body>
        </LaptopUpOnly>
      </Flex>
      <Flex mt="1rem" wrap="wrap">
        <Flex mb="0.75rem" wrap="wrap">
          <CustomInput
            w="3rem"
            mr="0.75rem"
            units="ft"
            inputProps={{
              name: "heightFt",
              placeholder: "ft",
              value: values.heightFt,
              onChange: (event) => onChange(event.target.value, "heightFt", 2),
              isDisabled: !!fitStatus,
            }}
          />
          <CustomInput
            w="3rem"
            mr="0.75rem"
            units="in"
            inputProps={{
              name: "heightIn",
              placeholder: "in",
              value: values.heightIn,
              onChange: (event) => onChange(event.target.value, "heightIn", 2),
              isDisabled: !!fitStatus,
            }}
          />
          <CustomInput
            w="4.25rem"
            mr="0.75rem"
            units="lbs"
            inputProps={{
              name: "weight",
              placeholder: "lbs",
              value: values.weight,
              onChange: (event) => onChange(event.target.value, "weight", 3),
              isDisabled: !!fitStatus,
            }}
          />
          {!fitStatus && (
            <Button
              theme="secondary"
              px="0.625rem"
              whiteSpace="nowrap"
              boxSizing="content-box"
              onClick={() => onFit(getFit())}
            >
              Go
            </Button>
          )}
        </Flex>
        <Flex>
          {fitStatus === "fit" && (
            <Flex
              bg="#00C4D112"
              minW="7.25rem"
              h="3rem"
              justify="center"
              align="center"
              borderRadius="0.5rem"
              cursor="pointer"
              onClick={resetFit}
            >
              <SmallCaps color="primary" size="lg">
                it&apos;s a fit!
              </SmallCaps>
            </Flex>
          )}
          {fitStatus === "no-fit" && (
            <Flex
              bg="#F9A59A12"
              minW="7.25rem"
              h="3rem"
              justify="center"
              align="center"
              borderRadius="0.5rem"
              cursor="pointer"
              onClick={resetFit}
            >
              <SmallCaps color="sunrise" size="lg">
                <b>not</b> a fit
              </SmallCaps>
            </Flex>
          )}
        </Flex>
      </Flex>
      <LaptopDownOnly>
        <Body color="dawn" mt="1.5rem" maxWidth="80%" size="sm">
          For a more comprehensive fitting, use our BodyFit quiz.
        </Body>
      </LaptopDownOnly>
      <LaptopUpOnly>
        <Body color="dawn" mt="1.5rem" maxWidth="80%">
          For a more comprehensive fitting, use our BodyFit quiz in the sidebar
          👉
        </Body>
      </LaptopUpOnly>
    </Flex>
  )
}

export default QuickFit
