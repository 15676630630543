// @ts-nocheck

import { Box, Flex, Avatar, Heading, Text, Image, useDisclosure } from "@chakra-ui/core"

import loadable from "@loadable/component"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import R from "ramda"
import React from "react"

import BikeImages from "../components/BikeProductPage/BikeImages"
import FitBadge from "../components/BikeProductPage/FitBadge"
import Header from "../components/BikeProductPage/Header"
import Slider from "../components/Slider"
import ProductActions, {
  BikePageHeader,
} from "../components/BikeProductPage/ProductActions"
import { ConnectedAddedToCartModal as AddedToCartModal } from "../components/Cart/AddedToCartModal"
import { getFixedPrice, getFixedPriceFromValue } from "../components/Cart/utils"
import { ColorType } from "../components/ColorPicker"
import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import LayoutBuilder from "../components/LayoutBuilder"
import QuickFit from "../components/BikeProductPage/QuickFit"
import { OptionType } from "../components/BoxSelector"
import CloseSideBar from "../components/CloseSideBar"
import ModalButton from "../components/ModalButton"
import DetailsItem from "../components/DetailsItem"
import Notice from "../components/Notice"
import Body from "../components/typography/Body"
import SEOBlock from "../components/SEOBlock"
import ContentfulRichText from "../components/ContentfulRichText"
import SlashedText from "../components/SlashedText"

import { FluidObject } from "gatsby-image"
import { StringParam, useQueryParam } from "use-query-params"
import { BicycleProductQuery } from "../../graphql-types"
import Banner from "../components/Banner"
import Embed from "../components/Embed"
import ReviewsButton from "../components/ReviewsButton"
import { trackAddToCart } from "../components/Scripts/EventTracker"
import SEO from "../components/boilerplate/seo"
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../constants/measurements"
import useJudgeMeReviews from "../hooks/useJudgeMeReviews"
import { useProductSEOData } from "../hooks/useProductSEOData"
import useGoogleAnalytics from "../components/GoogleAnalytics/hooks/useGoogleAnalytics"
import useShopifyVariantPricing from "../hooks/useShopifyVariantPricing"
import { CartItem } from "../redux/models/checkout"
import { BaseDispatch, BaseRootState } from "../redux/store"
import "../styles/css/productPage.css"
import { createContentfulImageMap, decodeShopifyId } from "../utils"
import { LaptopDownOnly, LaptopUpOnly, bp, bpWithSidebar } from "../utils/MediaQueries"
import { BikeProductPageDataType } from "../utils/TransformTypes"
import { isFit } from "../utils/bodyFit"
import { getPreorderLabel, useFetchPreorderInfo } from "../utils/preorder"
import { rawRichTextToString } from "../utils/richText"

const LoadableAffirmWidget = loadable(
  () => import("../components/AffirmWidget")
)

export type Accessory = {
  contentful_id: string
  shopifyId: string
  internalTitle: string
  productListImage: any
  title: string
  topLevelImages: any
  type: string
  variants: any
  pricing: any
}

export interface BicycleProductPageProps {
  data: BicycleProductQuery & {
    bike: BikeProductPageDataType
    freeShipping: {
      modalTitle: string
      body: string
    }
    testRide: {
      modalTitle: string
      body: string
    }
    ninetyTestRide: {
      modalTitle: string
      body: string
    }
    [key: string]: any
  }
  pageContext: any
  location: Location
}

const BicycleProductPage: React.FC<BicycleProductPageProps> = ({
  data: { bike, freeShipping, testRide, ninetyTestRide, layoutImages, ...data },
  pageContext,
  location,
}) => {
  const [hasWarranty, setHasWarranty] = React.useState(false)
  const [addedToCart, setAddedToCart] = React.useState(false)
  const addedToCartModal = useDisclosure()
  const [selectedAccessories, setSelectedAccessories] = React.useState<
    Accessory[]
  >([])

  let imageMap: any = undefined
  if (layoutImages !== undefined) {
    imageMap = createContentfulImageMap(layoutImages)
  }

  // Redux
  const {
    lineItems,
    quantity,
    isSideBarOpen,
    isCartLoading,
    warranty,
    bodyFit,
    bikeResults,
    answers,
  } = useSelector((state: BaseRootState) => ({
    lineItems: state.checkout.data?.lineItems.edges,
    quantity: state.checkout.quantity,
    isSideBarOpen: state.sidebar.isOpen,
    isCartLoading: state.checkout.isLoading,
    warranty: state.bikes.warranty,
    bodyFit: state.user.bodyFit,
    bikeResults: state.bikes.bikeResults,
    answers: state.quiz.answers,
  }))
  const dispatch = useDispatch<BaseDispatch>()

  // Calculate bike results if quiz taken
  React.useEffect(() => {
    if (bikeResults === null && answers.date !== "") {
      dispatch.bikes.calculateResults()
    }
  }, [bikeResults, answers])

  const matchPercent = bikeResults?.find(
    (result) => bike?.internalTitle === result.internalTitle
  )?.score

  // Pricing
  const {
    data: pricingData,
    pricingMap,
    loading,
  } = useShopifyVariantPricing([
    ...(bike?.variants || []).map((v) => v.shopifyId),
    warranty?.shopifyId,
  ])

  // Variants
  const [
    _uniqueProductId = decodeShopifyId(bike?.variants?.[0]?.shopifyId ?? null),
    setUniqueProductId,
  ] = useQueryParam("variant", StringParam)
  const uniqueProductId =
    _uniqueProductId === ""
      ? decodeShopifyId(bike?.variants?.[0]?.shopifyId ?? null)
      : _uniqueProductId
  const variant = bike?.variants?.find(
    (v) => decodeShopifyId(v.shopifyId) === uniqueProductId
  )
  const [currentSpeed, setCurrentSpeed] = React.useState(variant?.speed ?? "")
  const [currentColor, setCurrentColor] = React.useState(
    variant?.color?.name ?? ""
  )
  const onColorChange = (color: ColorType) => {
    const newVariant = bike?.variants.find(
      (variant) =>
        variant.color?.name === color.colorId && variant.speed === currentSpeed
    )
    if (!newVariant) {
      const newSpeed = bike?.speeds?.find((speed) =>
        speed?.variants?.find((v) => v?.color?.name === color.colorId)
      )

      if (newSpeed?.speed) {
        setCurrentSpeed(newSpeed.speed)
      }
    }
    setCurrentColor(color.colorId)
  }
  const onSpeedChange = (speed: string) => {
    const newVariant = bike?.variants.find(
      (variant) =>
        variant.color?.name === currentColor && variant.speed === speed
    )
    if (!newVariant) {
      const newSpeed = bike?.speeds?.find((s) => s?.speed == speed)
      const newColor = newSpeed?.variants?.[0]

      if (newColor?.color?.name) {
        setCurrentColor(newColor?.color?.name)
      }
    }
    setCurrentSpeed(speed)
  }

  const shopifyId = variant?.shopifyId ?? ""
  const pricing = pricingMap[shopifyId]
  const priceAmount = pricing?.priceV2.amount
  const warrantyPriceAmount = pricingMap[warranty?.shopifyId!]?.priceV2.amount
  const priceValue = Number(priceAmount || -1)
  const warrantyPriceValue = Number(warrantyPriceAmount || 0)
  const price = getFixedPrice(priceAmount)
  const warrantyPrice = getFixedPrice(warrantyPriceAmount)
  const compareAtPrice = pricing?.compareAtPriceV2?.amount
    ? getFixedPrice(pricing.compareAtPriceV2.amount)
    : undefined
  const totalPriceValue =
    priceValue >= 0 ? priceValue + (hasWarranty ? warrantyPriceValue : 0) : -1
  const totalPrice = getFixedPriceFromValue(
    totalPriceValue >= 0 ? totalPriceValue : undefined
  )
  const bikeType = new Set(bike?.type).has("Electric")
    ? "Electric Bicycle"
    : "Bicycle"

  // Contact Number
  const showContactNumberCurrent = variant?.product?.showContactNumber
  const alternatePhoneNumberCurrent = variant?.product?.alternatePhoneNumber
    ? variant?.product?.alternatePhoneNumber
    : "(310)982-2877"
  const contactNumberString = `tel:${alternatePhoneNumberCurrent}`

  // Reviews
  const reviews = useJudgeMeReviews(variant?.product.internalTitle)

  // Preorder info
  const productShopifyId = pricing?.product.id
  const {
    data: preorderInfo,
    error: preorderInfoError,
    loading: preorderInfoLoading,
  } = useFetchPreorderInfo([])
  const preorderLabel = getPreorderLabel(
    preorderInfo?.data,
    uniqueProductId,
    productShopifyId,
    false
  )
  const isAvailable =
    pricing && pricing.availableForSale && !pricing.currentlyNotInStock
  const isPreorder =
    pricing && pricing.availableForSale && pricing.currentlyNotInStock

  // Quick Fit
  const fitStatus = bodyFit
    ? isFit(bike?.bodyFit, bodyFit)
      ? "fit"
      : "no-fit"
    : null

  const speedOptions = bike?.variants
    ?.reduce((acc, cur) => {
      const speedInAcc = acc.find(({ name }) => name === cur.speed)
      if (!speedInAcc) {
        return [
          ...acc,
          {
            label: `${cur.speed} speed`,
            shortLabel: cur.speed,
            name: cur.speed,
            isDisabled: !bike?.variants.find(
              (v) => v.speed === cur.speed && v.color?.name === currentColor
            ),
          },
        ]
      }
      return acc
    }, [] as Array<OptionType>)
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))

  const colorOptions: Array<ColorType> = bike?.variants?.reduce((acc, cur) => {
    const colorInAcc = acc.find(({ colorId }) => colorId === cur.color?.name)
    if (!colorInAcc) {
      return [
        ...acc,
        {
          colorId: cur.color?.name,
          colorCode: cur.color?.hexCode,
          isDisabled: !bike?.variants.find(
            (v) => v.color?.name === cur.color?.name && v.speed === currentSpeed
          ),
        },
      ]
    }
    return acc
  }, [])

  // Hide side bar
  React.useEffect(() => {
    if (isSideBarOpen) dispatch.sidebar.setIsOpen(false)
  }, [])

  // Update current product id
  React.useEffect(() => {
    const newVariant = bike?.variants.find(
      (variant) =>
        variant.color?.name === currentColor && variant.speed === currentSpeed
    )
    setUniqueProductId(
      decodeShopifyId(newVariant?.shopifyId ?? null),
      "replaceIn"
    )
  }, [currentColor, currentSpeed])

  // Show AddedToCartModal after cart is done loading
  React.useEffect(() => {
    if (addedToCart && isCartLoading === false) {
      setAddedToCart(false)
      addedToCartModal.onOpen()
    }
  }, [isCartLoading])

  // On Clicks
  const onWarrantySelect = (name: string) => {
    setHasWarranty(name === "forever")
  }
  const onCustomize = () => {
    navigate(`/customizer/${bike?.internalTitle}`)
  }
  // Google Analytics Added Warranty
  const warrantyGA = useGoogleAnalytics({
    category: "Warranty",
    action: "Added Product",
    shouldFireOnFirstRender: false,
  })
  const addBicycleToCartGA = useGoogleAnalytics({
    category: "Bicycle",
    action: "Added Product",
    shouldFireOnFirstRender: false,
  })
  const onAddToCart = () => {
    const childCartItems: CartItem[] = []
    if (hasWarranty) {
      childCartItems.push({
        type: "warranty",
        productSlug: warranty?.accessory_model?.[0]?.internalTitle,
        contentfulProductId: warranty?.accessory_model?.[0]?.contentful_id,
        contentfulVariantId: warranty?.contentful_id,
        variantId: warranty?.shopifyId,
        parentBundleId: variant?.shopifyId,
      } as CartItem)
      warrantyGA.fireEvent()
      trackAddToCart({
        productId: decodeShopifyId(warranty?.shopifyId) || "",
        productName: warranty?.title || "Forever Warranty",
        productType: "Warranty",
        value: warrantyPriceAmount,
      })
    }

    addBicycleToCartGA.fireEvent()

    let itemList = [
      {
        cartItem: {
          type: "bike",
          productSlug: bike?.internalTitle,
          contentfulProductId: variant?.contentfulProductId,
          contentfulVariantId: variant?.contentfulVariantId,
          variantId: variant?.shopifyId,
          isPreorder,
          preorderInfo: preorderLabel,
        } as CartItem,
        childCartItems,
      },
    ]

    if (Array.isArray(selectedAccessories) && selectedAccessories.length > 0) {
      selectedAccessories.map((upsellItem) => {
        itemList.push({
          cartItem: {
            type: "accessory",
            productSlug: upsellItem?.internalTitle,
            contentfulProductId: upsellItem?.contentful_id,
            contentfulVariantId: upsellItem?.contentful_id,
            variantId: upsellItem?.shopifyId,
            isPreorder,
            preorderInfo: preorderLabel,
          } as CartItem,
          childCartItems: [],
        })

        const value = upsellItem?.pricing?.priceV2?.amount || 0
        trackAddToCart({
          productId: decodeShopifyId(upsellItem?.shopifyId) || "",
          productName: upsellItem?.title || "",
          productType: "Accessory",
          value,
        })
      })
    }

    dispatch.checkout.addToCartBulk(itemList)

    // dispatch.checkout.addToCart(item)
    setAddedToCart(true)
    trackAddToCart({
      productId: decodeShopifyId(variant?.shopifyId) || "",
      productName: bike?.title,
      productType: bikeType,
      value: priceAmount,
    })
  }

  // Section Headers
  const sectionHeaders =
    pageContext?.layoutData?.layout
      .filter(
        ({ headingLabel, layoutId }) =>
          headingLabel !== "" && layoutId === "top-subtitle-heading"
      )
      .map(({ headingLabel }) => headingLabel) || []

  // Bike images
  const bikeImages = [
    ...(variant?.layout ?? []),
    ...(bike?.imageLayout?.layout ?? []),
  ].filter((x) => !!x)

  // Free shipping / Test Ride
  const {
    enableFreeShippingMessage,
    enable365DayTestRideMessage,
    enable90DayTestRideMessage,
    enableNationwideServiceMessage,
    enableWarrantyMessage,
  } = bike

  // SEO
  const productSeo = useProductSEOData({
    productHandle: variant?.product.internalTitle,
    bikeData: {
      data: {
        bike,
        freeShipping,
        testRide,
        ninetyTestRide,
        layoutImages,
        ...data,
      },
      pageContext,
      location,
    },
    pricing: {
      data: pricingData,
      isLoading: loading,
    },
  })
  // Google Analytics
  useGoogleAnalytics({
    category: bikeType,
    action: "Viewed Product",
  })

  const onAccessoryChange = (accessory: Accessory) => {
    const isAccessoryPresent = selectedAccessories?.find(
      (acc) => acc?.contentful_id === accessory?.contentful_id
    )
    if (isAccessoryPresent) {
      setSelectedAccessories(
        selectedAccessories?.filter(
          (acc) => acc?.contentful_id !== accessory?.contentful_id
        )
      )
    } else {
      setSelectedAccessories([...selectedAccessories, accessory])
    }
  }

  var currentVariantData = variant?.product?.variants?.find(
    (v) => v?.contentful_id === variant?.contentfulVariantId
  )

  // Get Cart Items from Redux as 'lineItems'
  let currentCartItemIds = lineItems?.map((item) => item?.node?.variant?.id)
  currentCartItemIds = [...new Set(currentCartItemIds)]
  // Filter
  let filteredAddons = [
    ...(currentVariantData?.cartAddons || []),
    ...(currentVariantData?.accessoryUpsell || []),
  ]
    .filter((addon) => {
      return !currentCartItemIds?.includes(addon?.shopifyId)
    })
    .filter((addon, index, self) => {
      return self.findIndex((a) => a?.shopifyId === addon?.shopifyId) === index
    })
    .slice(0, 6)

  return (
    <>
      <SEO
        title={variant?.product.seoTitle || bike?.seoTitle || bike?.title}
        description={
          variant?.product?.seoDescription?.seoDescription ||
          bike?.seoDescription?.seoDescription ||
          ""
        }
        image={imageMap[bikeImages[0]?.image?.assetId]?.src || undefined}
        location={location}
        {...productSeo}
      />
      {bike?.banner?.fluid && (
        <Banner
          image={bike?.banner.fluid as FluidObject}
          mobileImage={(bike?.mobileBanner?.fluid as FluidObject) || undefined}
          paddingTop={bpWithSidebar(
            `calc( ${MOBILE_HEADER_HEIGHT}px + 3.25rem )`,
            `${HEADER_HEIGHT}px`,
            isSideBarOpen
          )}
          url={bike?.bannerSlug}
        />
      )}
      <CloseSideBar>
        <Box>
          <Header labels={["product", ...sectionHeaders]} />
          <MaxWidthGrid
            id="product"
            pt="4rem"
            isSideBarOpen={isSideBarOpen}
            mb={bp("3.5rem", "5.5rem")}
          >
            <LaptopDownOnly isSideBarOpen={isSideBarOpen}>
              <Box gridColumn="1 / 3" mb="0.75rem">
                <BikePageHeader
                  title={bike?.formattedTitle}
                  isSideBarOpen={isSideBarOpen}
                />
                <Flex>
                  <Body fontWeight="bold" color="night" mb="0.1rem">
                    {totalPrice}
                  </Body>
                  {compareAtPrice && (
                    <SlashedText ml="1rem">{compareAtPrice}</SlashedText>
                  )}
                </Flex>
                <LoadableAffirmWidget
                  pageType="product"
                  amount={totalPriceValue >= 0 ? totalPriceValue : 0}
                />
                <ReviewsButton
                  bikeName={`${bike?.title} ${currentSpeed} Speed`}
                  mt="1.2rem"
                  w="fit-content"
                  mb="1.2875rem"
                  isLoading={reviews.isLoading}
                  rating={reviews.avgRating}
                  reviewCount={reviews.numReviews}
                  reviews={R.uniqBy(R.prop("id"), reviews.seoReviews)}
                  productId={reviews.judgemeProduct?.product.id}
                  loadMoreReviews={
                    reviews.hasMoreToLoad ? reviews.loadMore : undefined
                  }
                  isLoadingMore={reviews.isLoadingMore}
                />
              </Box>
            </LaptopDownOnly>
            <Box
              pos="relative"
              gridColumn={bpWithSidebar("1 / 3", "2 / 8", isSideBarOpen)}
            >
              <FitBadge
                matchPercent={matchPercent}
                fit={fitStatus}
                pos="absolute"
                top={0}
                right={0}
                zIndex={1}
              />
              <BikeImages data={bikeImages} imageMap={imageMap} w="100%" />
            </Box>
            <Flex
              flexDirection="column"
              gridColumn={bpWithSidebar("1 / 3", "9 / 14", isSideBarOpen)}
              pl={[null, null, null, null, null, "12.5%"]}
            >
              {pricing && (
                <ProductActions
                  bikeTitle={bike?.formattedTitle}
                  price={totalPrice}
                  compareAtPrice={compareAtPrice}
                  colorOptions={colorOptions}
                  onColorSelect={onColorChange}
                  initialColorId={currentColor ?? ""}
                  speedOptions={speedOptions}
                  onSpeedSelect={onSpeedChange}
                  initialSpeed={currentSpeed ?? ""}
                  freeShipping={freeShipping}
                  preorderLabel={preorderLabel}
                  isPreorder={isPreorder}
                  warrantyOptions={
                    bike?.type?.includes("Standard")
                      ? [
                          {
                            label: "1 year warranty",
                            shortLabel: "1 year",
                            name: "1year",
                            subText: "",
                          },
                          {
                            label: "Forever warranty",
                            shortLabel: "Forever",
                            name: "forever",
                            subText: `(+${warrantyPrice})`,
                          },
                        ]
                      : [
                          {
                            label: "1 year warranty",
                            shortLabel: "1 year",
                            name: "1year",
                            subText: "",
                          },
                        ]
                  }
                  onWarrantySelect={onWarrantySelect}
                  onCustomize={onCustomize}
                  onAddToCart={onAddToCart}
                  isCartLoading={
                    isCartLoading ||
                    (pricing?.currentlyNotInStock && preorderInfoLoading)
                  }
                  variantId={shopifyId}
                  saleStatus={
                    isAvailable
                      ? "available"
                      : isPreorder
                      ? "preorder"
                      : "out-of-stock"
                  }
                  hideWarranty={!!bike?.disableWarranty}
                  hideCustomizer={variant?.hideCustomizerButton}
                  limitedQuantity={
                    (pricing?.quantityAvailable || Infinity) < 10
                      ? pricing.quantityAvailable
                      : undefined
                  }
                  variant={variant}
                  bike={bike}
                  currentSpeed={currentSpeed}
                  accessoryUpsell={currentVariantData?.accessoryUpsell}
                  selectedAccessories={selectedAccessories}
                  onAccessoryChange={onAccessoryChange}
                />
              )}
              <Box mt={["2.375rem", null, null, null, "2.5625rem"]}>
                {!matchPercent && (
                  <QuickFit
                    onFit={(values) => {
                      dispatch.user.setBodyFit(values)
                    }}
                    fitStatus={fitStatus}
                    bodyFit={bodyFit}
                  />
                )}
              </Box>
              {/* Show Contact number */}
              {showContactNumberCurrent && (
                <Flex
                  mt={["2.375rem", null, null, null, "2.5625rem"]}
                  flexDirection="column"
                  alignItems={bp("center", "flex-start")}
                >
                  <Body>
                    Questions? We are happy to help.
                  </Body>
                  <Body>
                    Call us at -{" "}
                    <a href={contactNumberString}>
                      <u style={{ fontWeight: 500 }}>
                        {alternatePhoneNumberCurrent}
                      </u>
                    </a>
                  </Body>
                </Flex>
              )}
            </Flex>
          </MaxWidthGrid>
          <LayoutBuilder
            data={pageContext.layoutData}
            imageMap={imageMap}
            variant={variant?.speed}
            className="ProductPageLayoutSections"
          />
          {bike?.seoContentBlock && (
            <SEOBlock
              label={`Learn more about ${bike.title}`}
              mb="80"
              image={bike?.seoBackgroundImage?.fluid as FluidObject}
              video={bike?.seoContentBlockVideo?.seoContentBlockVideo}
            >
              <Body as="h2" fontWeight="bold" mb="20">
                {pageContext.pageTitle}
              </Body>
              <ContentfulRichText richText={bike?.seoContentBlock} />
            </SEOBlock>
          )}
          <AddedToCartModal
            isOpen={addedToCartModal.isOpen}
            onClose={addedToCartModal.onClose}
            formattedTitle={bike?.formattedTitle}
            image={variant?.productListingImage}
            bike={bike}
            cartAddons={filteredAddons}
            details={
              <>
                <DetailsItem>{variant?.color?.name}</DetailsItem>
                <DetailsItem>{variant?.speed} Speed</DetailsItem>
                {hasWarranty && (
                  <DetailsItem>Forever Warranty (+{warrantyPrice})</DetailsItem>
                )}
              </>
            }
            price={totalPrice}
          />
        </Box>
      </CloseSideBar>
    </>
  )
}

export default BicycleProductPage
